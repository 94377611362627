import { Box, Select, TextField } from '@material-ui/core'
import { Autocomplete, Pagination } from '@mui/material'
import React, { ChangeEvent, SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react'
import { Input } from '../../components/common components/Input'
import { Datepicker } from '../../components/common components/Datepicker'
import { Radio } from '../../components/common components/Radio'
import { Button } from '../../components/common components/Button'
import { useForm } from 'react-hook-form';
import { CREATE_ELEMENT, FORM_INPUTS, CONSTANTS } from './SalesPromotionData'
import { useHistory } from 'react-router-dom'
import { fetchAllSalesPromotionPayloadInterface, postFetchAllSalesPromotion } from '../../contractManagement/thunks/postFetchAllSalesPromotion'
import { AnyAaaaRecord } from 'dns'
import { useDispatch, useSelector } from 'react-redux'
import SaleList, { SearchFormData } from '../../appStore/actions/saleList'
import authContext from '../../context/auth-context'
import moment from 'moment'
import { Label } from '../../components/common components/Label'
import { postDeleteSalesPromotion } from '../../contractManagement/thunks/postDeleteSalesPromotion'
import SpinnerLoader from '../bookingConfirmation/Utils/spinner'
import usePagination from '../../comman/pagination'
import swal from 'sweetalert'
import { boolean } from 'yup'


const SalesPromotion = () => {
    const { orgId } = useContext(authContext);
    const { watch, setValue, register, handleSubmit, formState: { errors }, reset } = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        salesPromotionApiCall(data)
    };
    let SaleListData = useSelector((state) => state.SaleListDataForget.saleList);
    let searchFormData = useSelector((state) => state.SaleListDataForget.searchFormData);
    const [salesPromotions, setSalesPromotions] = useState(()=>SaleListData)
    const [loader, setLoader] = useState(false)
    const [bookingChannelState, setBookingChannelState] = useState(searchFormData?.bookingChannel ? searchFormData?.bookingChannel  : '')
    const [supplierState, setSupplierState] = useState(searchFormData?.supplier ? searchFormData?.supplier : '')
    const [agencyState, setAgencyState] = useState(searchFormData?.agencyInclusion ? searchFormData?.agencyInclusion : null)
    const [consortium, setConsortium] = useState(searchFormData?.consortiumInclusion ? searchFormData?.consortiumInclusion : null)
    const [agentState, setAgentState] = useState(searchFormData?.agentExclusion ? searchFormData?.agentExclusion : null)
    const [reservationDateFrom, setReservationDateFrom] = useState(searchFormData?.reservationDateFrom ? searchFormData?.reservationDateFrom : "")
    const [reservationDateTo, setReservationDateTo] = useState(searchFormData?.reservationDateTo ? searchFormData?.reservationDateTo : "")
    const [page, setPage] = useState(1);
    const [textFieldValue, setTextFieldValue] = useState("");
    const [consortiumId, setConsortiumId] = useState(undefined);
    const [agencyData, setAgencyData] = useState([]);
    const [consortiumList, setConsortiumList] = useState([]);

    const bookingChannel = [
        {label:'API-B2B',value:'API-B2B'},
        {label:'API-B2C',value:'API-B2C'},
        {label:'Call Center',value:'CallCenter'},
        {label:'Online',value:'online'}
    ]
    const supplier = [
        {label:'Sabre',value:'Sabre'},
        {label:'Sabre NDC',value:'SabreNDC'},
        {label:'Duffle',value:'Duffle'},
        {label:'Amadeus NDC',value:'Amadeus_NDC'},
        {label:'AC NDC',value:'AC_NDC'}
    ]
    const agencyFocusData = (value) => {
        if (value.length > 1) {
            fetch(
              `${process.env.REACT_APP_BASE_URL}/travelBusinesses/autoSuggest?orgId=${orgId}&adAgencyCode=${value}`,
              {
                method: 'GET',
                redirect: 'follow',
              },
            )
            .then((response) => response.json())
            .then((result) => {
                result.Items.forEach((data) => {
                    data['seacrhVariable'] = data.adAgencyCode + ' - ' + data.searchTravelBusinessesName
                })
                return result.message === "No records found." ? setAgencyData([]) : setAgencyData(result.Items.map((item) => {
                    const labelKey = Object.keys(item).find(key => key.includes('adTravelAgencyName'));
                    const optionKey = Object.keys(item).find(key => key.includes('adAgencyCode'));
                    
                    return {
                      label: `${item[optionKey]} - ${item[labelKey]}`,
                      value: `${item[optionKey]}`
                    };
                  }))
            })
            .catch((error) => console.error('Error: ', error));
        }
    };

    const onChangeBookingChannel=(e)=>{
        setBookingChannelState(e);
    }

    const onChangeSupplier=(e)=>{
        setSupplierState(e);
    }

    const onChangeAgency=useCallback(async(e)=>{
        if(!e) return;
        setAgencyState(e?.target?.value);
        await agencyFocusData(e?.target?.value);
    }, [])   
    
    const fetchOptions = async (query) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/travelBusinesses/autoSuggest?orgId=${orgId}&adAgencyCode=${query}`);
          if (!response.ok) {
            throw new Error('Failed to fetch options');
          }
          const data = await response.json();
          return(
            !data?.message ? data?.map((item) => {
              const labelKey = Object.keys(item).find((key) =>
                key.includes('searchTravelBusinessesName')
              );
              const optionKey = Object.keys(item).find((key) =>
                key.includes('adAgencyCode')
              );
              const travelBusinessesId = Object.keys(item).find((key) =>
                key.includes('travelBusinessesId')
              );
    
              return {
                label: `${item[optionKey]} - ${item[labelKey]}`,
                value: `${item[optionKey]}`,
                travelBusinessesId:`${item[travelBusinessesId]}`
              };
            }) : []
          );
        } catch (error) {
          console.error('Error fetching options:', error);
        }
      };

      const fetchOptionsAgent = async (query) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/external/autoSuggestV2?orgId=${orgId}&name=${query}&travelBusinessesId=${agencyState?.travelBusinessesId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch options');
          }
          const data = await response.json();
          return(
            data?.Items?.map((item) => {
              const firstName = Object.keys(item).find((key) =>
                key.includes('firstName')
              );
              const lastName = Object.keys(item).find((key) =>
                key.includes('lastName')
              );
              const userName = Object.keys(item).find((key) =>
                key.includes('username')
              );
    
              return {
                label: `${item[firstName]} ${item[lastName]} (${item[userName]})`,
                value: `${item[userName]}`
              };
            })
          );
        } catch (error) {
          console.error('Error fetching options:', error);
        }
      };

      const onChangeAgencyForTheParent = async(a) => {
       setAgencyState(a);
       if(a==null){setAgentState(null);setConsortiumId(undefined)}
       const responseAgency = await getAgencyDetails(a?.travelBusinessesId)
      }

    const getAgencyDetails = (agencyId) => {
        const url = `${process.env.REACT_APP_BASE_URL}/travelBusinesses?travelBusinessesId=${agencyId}&orgId=${orgId}&walletRequired=true`;

        fetch(url, {
            method: 'GET',
            redirect: 'follow'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                setConsortiumId(data?.consortiumId)
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });

    }

      const handleDateChange = (event, setterFunction) => {
        setterFunction(event.target.value);
      };

      const getConsortium = async() => {
        let res;
        await fetch(`${process.env.REACT_APP_BASE_URL}/consortium/all?orgId=${orgId}`, {
            method: "POST",
            redirect: "follow",
          })
            .then((response) => response.json())
            .then((result) => res = result.Items?.map((item) => {
                const consortiumName = Object.keys(item).find((key) =>
                  key.includes('consortiumName')
                );
                const consortiumId = Object.keys(item).find((key) =>
                  key.includes('consortiumId')
                );
      
                return {
                  label: `${item[consortiumName]}`,
                  value: `${item[consortiumId]}`
                };
              }))
            .catch((error) => console.error("Error: ", error));
            res.length > 0 && setConsortiumList(res)
            return res    
    }

    const onChangeConsortiumForTheParent = async(a) => {
        setConsortium(a);
       }

       useEffect(() => {
         getConsortium();
       }, []);

    const FORM_DATA = {
        "First Row": {
            key: "",
            className: "row",
            data: [
                {
                    component: FORM_INPUTS[CONSTANTS.AUOCOMPLETE],
                    isChanged: false,
                    props: {
                        value:consortium,
                        fetchOptions:getConsortium,
                        onChange:onChangeConsortiumForTheParent,
                        divClass: "cm-col",
                        className: "form-control cm-input",
                        defaultValue: "",
                        name: "consortiumInclusion",
                        placeholder: "Consortium",
                        label: "Consortium",
                        key: "consortiumInclusion",
                        labelClassName: 'cm-head-label',
                        labelvisible: true,
                        defaultOption: consortiumList,
                        fetchOnce : true
                    },
                },
                // {
                //     component: FORM_INPUTS[CONSTANTS.INPUT],
                //     isChanged: false,
                //     props: {
                //         value: searchFormData?.consortiumInclusion,
                //         divClass: "cm-col",
                //         className: "form-control cm-input",
                //         defaultValue: "",
                //         name: "Consortium",
                //         placeholder: "Consortium",
                //         key: "consortiumInclusion",
                //         labelClassName: 'cm-head-label',
                //         labelName: 'Consortium',
                //         labelvisible: true,
                //         onChange: ({target}) => setValue('consortiumInclusion',target?.value)
                //     },
                // },
                {
                    component: FORM_INPUTS[CONSTANTS.INPUT],
                    isChanged: false,
                        props: {
                        ...register('agencyLocation'),
                        divClass: "cm-col",
                        className: "form-control cm-input",
                        defaultValue: "",
                        name: "AgencyLocation",
                        placeholder: "Agency Location",
                        key: "agencyLocation",
                        labelClassName: 'cm-head-label',
                        labelName: 'Agency Location',
                        labelvisible: true,
                        // onChange: ({target}) => {let temp = {...searchFormData}; temp['agencyLocation'] = target?.value; SearchFormData(temp)}
                        onChange: (e) => {
                            // Update react-hook-form value without causing re-render
                            setValue('agencyLocation', e.target.value, { shouldValidate: true });
                          }
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.AUOCOMPLETE],
                    isChanged: false,
                    props: {
                        value:agencyState,
                        fetchOptions:fetchOptions,
                        onChange:onChangeAgencyForTheParent,
                        divClass: "cm-col",
                        className: "form-control cm-input",
                        defaultValue: "",
                        name: "agencyInclusion",
                        placeholder: "Agency",
                        label: "Agency",
                        key: "agencyInclusion",
                        labelClassName: 'cm-head-label',
                        labelvisible: true
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.AUOCOMPLETE],
                    isChanged: false,
                    props: {
                        disabled: agencyState == null,
                        value:agentState,
                        fetchOptions: fetchOptionsAgent,
                        onChange:(e)=>{setAgentState(e)},
                        divClass: "cm-col",
                        className: "form-control cm-input",
                        defaultValue: "",
                        name: "Agent",
                        placeholder: "Agent",
                        label: "Agent",
                        key: "agentExclusion",
                        labelClassName: 'cm-head-label',
                        labelvisible: true,
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.SELECT],
                    isChanged: false,
                    props: {
                        divClass: "cm-col",
                        className: "form-control cm-input",
                        defaultValue: "",
                        name: "bookingChannel",
                        key: "bookingChannel",
                        value:bookingChannelState,
                        labelClassName: 'cm-head-label',
                        labelName: 'Booking Channel',
                        labelvisible: true,
                        options: bookingChannel,
                        onChange:onChangeBookingChannel
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.SELECT],
                    isChanged: false,
                    props: {
                        divClass: "cm-col",
                        className: "form-control cm-input",
                        defaultValue: "",
                        name: "Supplier",
                        key: "supplier",
                        labelClassName: 'cm-head-label',
                        labelName: 'Supplier',
                        labelvisible: true,
                        options:supplier,
                        value:supplierState,
                        onChange:onChangeSupplier
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.INPUT],
                    isChanged: false,
                    props: {
                        ...register('airline'),
                        divClass: "cm-col",
                        className: "form-control cm-input",
                        defaultValue: "",
                        name: "Carrier",
                        placeholder: "Carrier",
                        key: "airline",
                        labelClassName: 'cm-head-label',
                        labelName: 'Carrier',
                        labelvisible: true,
                        onChange: (e) => {
                            // Update react-hook-form value without causing re-render
                            setValue('airline', e.target.value, { shouldValidate: true });
                          }
                    },
                }
            ]
        },
        "Second Row": {
            key: "",
            className: "row",
            data: [
                {
                    component: FORM_INPUTS[CONSTANTS.DATEPICKER],
                    isChanged: false,
                    props: {
                        key: "reservationDateFrom",
                        divClass: "cm-col m-0",
                        name: "reservationDateFrom",
                        value: reservationDateFrom,
                        className: "BulkUpdate__DatePicker sales__DatePicker ",
                        format: "yyyy-MM-dd",
                        defaultValue: "",
                        labelClassName: 'cm-head-label',
                        labelName: 'Reservation Dates',
                        labelvisible: true,
                        onChange: (e)=>handleDateChange(e,setReservationDateFrom)
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.DATEPICKER],
                    isChanged: false,
                    props: {
                        value: reservationDateTo,
                        divClass: " pt-8",
                        name: "reservationDateTo",
                        key: "reservationDateTo",
                        className: "BulkUpdate__DatePicker sales__DatePicker ",
                        format: "yyyy-MM-dd",
                        defaultValue: "",
                        onChange: (e)=>handleDateChange(e,setReservationDateTo)
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.BUTTON],
                    isChanged: false,
                    props: {
                        name: "stableLast",
                        divClass: "text-right pt-6",
                        buttons: [
                            {
                                name: "clearButton",
                                type: "reset",
                                onClick:()=>{setSalesPromotions([]);setBookingChannelState('');setAgencyState(null);setAgentState(null);setSupplierState('');reset();dispatch(SearchFormData({}));setReservationDateTo("");setReservationDateFrom("")},
                                label: "Clear",
                                className: "cm-secondary-btn mr-2 mt-2",
                                format: "MM-dd-yyyy",
                                defaultValue: "",
                                rules: {
                                    required: true
                                }
                            },
                            {
                                name: "searchButton",
                                type: "submit",
                                label: "Search",
                                className: "cm-primary-btn mt-2",
                                format: "MM-dd-yyyy",
                                defaultValue: "",
                                rules: {
                                    required: true
                                }
                            }
                        ]
                    },
                }
            ]
        }
    };

    const removeEmptyStrings = (obj) => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] == '') {
                delete obj[key];
            }
        }
        return obj;
    }

    const salesPromotionApiCall = async (payloadParameters) => {
        setLoader(true)
        if(consortiumId){payloadParameters['consortiumInclusion'] = consortiumId}
        if(consortium?.value){payloadParameters['consortiumInclusion'] = consortium?.value}
        if(bookingChannelState?.length){
            payloadParameters['bookingChannel'] = bookingChannelState;
        }
        if(supplierState?.length){
            payloadParameters['supplier'] = supplierState;
        }
        if(agencyState?.value?.length){
            payloadParameters['agencyInclusion'] = agencyState?.value;
        }
        if(agentState?.value?.length){
            payloadParameters['agentExclusion'] = agentState?.value;
        }
        if(reservationDateFrom?.length){
            payloadParameters['reservationDateFrom'] = reservationDateFrom;
        }
        if(reservationDateTo?.length){
            payloadParameters['reservationDateTo'] = reservationDateTo;
        }
        if (Object.keys(removeEmptyStrings(payloadParameters))?.length == 0) {
            swal({
                text: "Please enter atleast one field",
                icon: "error",
            })
            setLoader(false)
            return null
        }
        const allSalesPromotion = await postFetchAllSalesPromotion(removeEmptyStrings(payloadParameters),setLoader)
        setLoader(false)
        setSalesPromotions(allSalesPromotion)
        dispatch(SaleList(allSalesPromotion))
        if(agencyState?.value?.length){
            payloadParameters['agencyInclusion'] = agencyState;
        }
        if(agentState?.value?.length){
            payloadParameters['agentExclusion'] = agentState;
        }
        dispatch(SearchFormData(payloadParameters))
    }

    const firstRow = (sales) => {
        let innerArray = [];
        if(sales?.ConsortiumInclusion || sales?.ConsortiumExclusion || sales?.AgencyLocationInclusion || sales?.AgencyLocationExclusion || sales?.AgencyInclusion || sales?.AgencyExclusion) {
            innerArray.push({
                'label': (sales?.ConsortiumInclusion || sales?.ConsortiumExclusion) ? 'Consortium' :
                    (sales?.AgencyLocationInclusion || sales?.AgencyLocationExclusion) ? 'Agency Location' :
                        ((sales?.AgencyInclusion || sales?.AgencyExclusion) && 'Agency'),
                "value": (sales?.ConsortiumInclusion || sales?.ConsortiumExclusion) ?
                    (sales?.ConsortiumInclusion || sales?.ConsortiumExclusion) :
                    (sales?.AgencyLocationInclusion || sales?.AgencyLocationExclusion) ?
                        (sales?.AgencyLocationInclusion || sales?.AgencyLocationExclusion) :
                        ((sales?.AgencyInclusion || sales?.AgencyExclusion) && sales?.AgencyInclusion ?
                            sales?.AgencyInclusion : sales?.AgencyExclusion)
            })
        }
        if (
            sales?.BookingChannelInclusion ||
            sales?.BookingChannelExclusion ||
            sales?.SupplierInclusion ||
            sales?.SupplierExclusion ||
            (sales?.Airline !== "YY" && sales?.Airline !== "")
        ) {
            innerArray.push({
                'label': (sales?.BookingChannelInclusion && Array.isArray(sales?.BookingChannelInclusion)) ? 'Booking Channel' :
                    (sales?.SupplierInclusion || sales?.SupplierExclusion) ? 'Supplier' :
                        (sales?.Airline !== "YY" && sales?.Airline !== "") && 'Carrier',
                "value": (sales?.BookingChannelInclusion && Array.isArray(sales?.BookingChannelInclusion)) ?
                    sales?.BookingChannelInclusion.join(',') :
                    (sales?.SupplierInclusion && Array.isArray(sales?.SupplierInclusion)) ? sales?.SupplierInclusion.join(',') :
                        (sales?.SupplierExclusion && Array.isArray(sales?.SupplierExclusion)) ? sales?.SupplierExclusion.join(',') :
                            (sales?.Airline !== "YY" && sales?.Airline !== "") && sales?.Airline
            })
        }        
        if(String(sales?.DestinationType).trim()?.length && sales?.DestinationInclusion){
            innerArray?.push({'label':'Destination', 'value': `${destinationType[String(sales?.DestinationType).trim()]} ${String(sales?.DestinationType).trim() != '5' ? '-' :''} ${String(sales?.DestinationType).trim() != '5' ? String(sales?.DestinationInclusion).trim() : ''}`})
        }
        innerArray.push({'label':"Reservation Date", 'value' : (sales?.ReservationDateFrom && sales?.ReservationDateTo) ? 'Restriction' :"No Restriction"})
        let data = sales;
        innerArray.push({ 'label': 'Void Allowed', 'value': data?.AdAllowCancellation ? <i className="fa-solid fa-circle-check" style={{'color':'green'}}></i> : <i className="fa-solid fa-circle-xmark" style={{'color':'red'}}></i> })
        innerArray.push({ 'label': 'Refund Allowed', 'value': data?.AdAllowRefund ? <i className="fa-solid fa-circle-check" style={{'color':'green'}}></i> : <i className="fa-solid fa-circle-xmark" style={{'color':'red'}}></i> })
        innerArray.push({ 'label': 'Reissue Allowed', 'value': data?.AdAllowReissue ? <i className="fa-solid fa-circle-check" style={{'color':'green'}}></i> : <i className="fa-solid fa-circle-xmark" style={{'color':'red'}}></i> })
        return innerArray;
    }

    const secondRow = (data) => {
        let innerArray = [];
       
        data?.TicketingFees?.length && innerArray.push({ 'label': 'Ticketing Fees', 'value': data?.TicketingFees })
        data?.VoidFees?.length && innerArray.push({ 'label': 'Void Fees', 'value': data?.VoidFees })
        data?.ReissueFees?.length && innerArray.push({ 'label': 'Reissue Fees', 'value': data?.ReissueFees })
        data?.RefundFees?.length && innerArray.push({ 'label': 'Refund Fees', 'value': data?.RefundFees })
        data?.BookingFees?.length && innerArray.push({ 'label': 'Booking Fees', 'value': data?.BookingFees })
        data?.MerchantFees?.length && innerArray.push({ 'label': 'Merchant Fees', 'value': data?.MerchantFees })
        return innerArray
    }

    const thirdRow = (data) => {
        let innerArray = [];
        innerArray.push({ 'label': 'Sell PUB at Cost', 'value': data?.SellPUBAtCost ? <i className="fa-solid fa-circle-check" style={{'color':'green'}}></i> : <i className="fa-solid fa-circle-xmark" style={{'color':'red'}}></i> })
        data?.LowestofDefaultMarkuporthisvalue?.length && innerArray.push({ 'label': 'Lowest of Default Markup or this value', 'value': data?.LowestofDefaultMarkuporthisvalue })
        data?.AddorRemoveFromDefaultMarkup?.length && innerArray.push({ 'label': 'Add or Remove from Default Markup', 'value': data?.AddorRemoveFromDefaultMarkup })
        data?.ExtraAgencyPUBCommission?.length && innerArray.push({ 'label': 'Extra Agency PUB Commission', 'value': data?.ExtraAgencyPUBCommission })
        data?.AddorRemovePUBCommissionAmount?.length && innerArray.push({ 'label': 'Add or Remove PUB commission Amount', 'value': data?.AddorRemovePUBCommissionAmount })
        return innerArray
    }

    const pageSize = 10;
    const count = Math.ceil(salesPromotions ? salesPromotions?.length / pageSize : 0);
    const _DATA = usePagination(salesPromotions ? salesPromotions : [], pageSize);
    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
      };

    const destinationType = {
        '1' : 'Airport',
        '2' : 'Country',
        '3' : 'Region',
        '4' : 'IATA',
        '5' : 'Global'
    }  

    return (


        <>
            <div>
                <div>
                    <div className="cm-head-2">
                        <div className="d-flex justify-content-between">
                            <span className="">Sales Promotion</span>
                        </div>
                    </div>
                    <div className="my-5 text-right">
                        <Button buttons={[{ className: "cm-yellow-btn ", onClick: () => { history.push('/NewSales') }, label: '+ Create Sales Promotion' }]} className="cm-yellow-btn " onClick={() => { }} label={'+Create Sales Promotion'} /> </div>
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    {Object.keys(FORM_DATA).map((key, indexMain) => (
                                        <div className='row'>
                                            {FORM_DATA[key]?.data.map((data, index) => (

                                                <div className={Object.keys(FORM_DATA).length - 1 == indexMain && index == FORM_DATA[key]?.data.length - 1 ? "col-md-6 text-right" : "col-md-3"}>

                                                    {CREATE_ELEMENT(data, register)}

                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </form>
                        </div>
                    </div>

                    {(_DATA.currentData()?.length && !loader) ? _DATA.currentData().map((sales) => {
                        return (
                            <div className='salePro_content' onClick={() => history.push(`/SalesEdit/${sales?.naID}`)}>
                                <div className='d-flex salePro_contentBox'>
                                    {firstRow(sales).map((firstRowValue) => {
                                        return (
                                            <span className='mr-4'>
                                                <span className='mr-2'>{firstRowValue?.label}</span>
                                                :
                                                <span className='ml-2'>{firstRowValue?.value}</span>
                                            </span>)
                                    })}
                                        {/* <div>
                                            <span>
                                                <button className='btn' onClick={() => history.push(`/SalesEdit/${sales?.naID}`)}>  <i className='fas fa-pen'></i></button>
                                            </span>
                                            <span>
                                                <button className='btn' onClick={()=>{postDeleteSalesPromotionCall(sales?.naID)}}>  <i className='fas fa-trash-alt'></i> </button>
                                            </span>
                                        </div> */}
                                </div>
                                {secondRow(sales)?.length ?
                                    <div className='d-flex salePro_contentBox'>
                                        {secondRow(sales).map((secondRowValue) => {
                                            return (
                                                <span className='mr-4'>
                                                    <span className='mr-2'>{secondRowValue?.label}</span>
                                                    :
                                                    <span className='ml-2'>{secondRowValue?.value}</span>
                                                </span>)
                                        })}
                                    </div>
                                    :
                                    null
                                }
                                {thirdRow(sales)?.length ?
                                    <div className='d-flex salePro_contentBox removeLowerDash'>
                                        {thirdRow(sales).map((thirdsRowValue) => {
                                            return (
                                                <span className='mr-4'>
                                                    <span className='mr-2'>{thirdsRowValue?.label}</span>
                                                    :
                                                    <span className='ml-2'>{thirdsRowValue?.value}</span>
                                                </span>)
                                        })}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        )
                    })
                        : loader ? <div className="col-md-12 text-center mt-3"><SpinnerLoader/> </div> : null}
                    {_DATA.currentData()?.length ? <Pagination
                        count={count}
                        size="large"
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                    /> : null}
                </div>
            </div>
        </>
    )
}

export default SalesPromotion