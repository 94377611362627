import { lazy, Suspense  } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import Addclasses from './contractManagement/classes/Addclasses';
import ViewAddPrices from './contractManagement/classes/Prices/ViewAddPrices/ViewAddPrices';
import Contractlist from './contractManagement/Contractlist/Contractlist';
import NewContractlist from './contractManagement/NewContractlist/NewContractlist';
import Contractmanagement from './contractManagement/contractmanagement';
import Agentlist from './pages/agentprofile/agentlist';
import Createprofile from './pages/agentprofile/createprofile';
import Updateprofile from './pages/agentprofile/updateprofile';
import { ContentRoute, Layout } from './_theme/layout';
import { useSelector } from "react-redux";
import CreateContract from './contractManagement/createContract';
import Createclass from './contractManagement/classes/Createclass';
import Myfile from './pages/myfiles/Myfile';
import BookingConfirmationContainer from './pages/myfiles/BookingConfirmationContainer';
import { SunquestAdminList } from './pages/sunquestAdmin/sunquestAdminList';  
import BulkUpdate from './contractManagement/NewContractlist/BulkUpdate';
import NewClass from './contractManagement/NewClass/NewClass';
import NewTariff from './contractManagement/NewTariff/NewTariff';
import NewPrix from './contractManagement/NewPrix/NewPrix';
import FraudCheck from './pages/fraudCheck/fraudCheck';
import SalesEdit from './pages/NewSale/SalesEdit';
import SalesPromotion from './pages/NewSale/SalesPromotion';
import Branding from './pages/branding/branding';


const LoginPage = lazy(() => import('./pages/auth/login'));
const ErrorPage = lazy(() => import('./pages/error'));
const HomePage = lazy(() => import('./pages/home'));

const BusinessPage = lazy(() => import('./pages/business'));
const BusinessCreate = lazy(() => import('./pages/business/create'));
const BusinessUpdate = lazy(() => import('./pages/business/update'));
const ConsortiumPage = lazy(() => import('./pages/consortium'));
const ConsortiumCreate = lazy(() => import('./pages/consortium/create'));
const ConsortiumUpdate = lazy(() => import('./pages/consortium/update'));
const OrganizationPage = lazy(() => import('./pages/organization'));
const OrganizationCreate = lazy(() => import('./pages/organization/create'));
const OrganizationUpdate = lazy(() => import('./pages/organization/update'));

const FeesPage = lazy(() => import('./pages/fees'));
const FeesUpdate = lazy(() => import('./pages/fees/update'));
const FeesView = lazy(() => import('./pages/fees/view'));
const TaxPage = lazy(() => import('./pages/tax'));
const TaxCreate = lazy(() => import('./pages/tax/create'));

const LoyaltyPage = lazy(() => import('./pages/loyalty'));
const LoyaltyUpdate = lazy(() => import('./pages/loyalty/update'));
const SalesPage = lazy(() => import('./pages/sales'));
const SalesCreate = lazy(() => import('./pages/sales/create'));
const FraudChecklistPage = lazy(() => import('./pages/fraudCheck/fraudCheck'));
const BrandingPage = lazy(() => import('./pages/branding/branding'))
const CreateBranding = lazy(() => import('./pages/branding/createBranding'))
export function Routes() {
const isUserLogin = useSelector((state:any) => state.user);
const isAuthorized: any = isUserLogin.isUserLogedIn;
  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <LoginPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorPage} />
      {/* <Route path="/logout" component={Logout} /> */}

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth" />
      ) : (
        <Layout>
          <Suspense fallback={<code>Loading...</code>}>
            <Switch>
              {<Redirect exact from="/" to="/home" />}
              <ContentRoute exact path="/home" component={HomePage} />

              <ContentRoute exact path="/businesses" component={BusinessPage} />
              <ContentRoute exact path="/businesses/new" component={BusinessCreate} />
              <ContentRoute exact path="/businesses/:travelBusinessesId" component={BusinessUpdate} />

              <ContentRoute exact path="/consortium" component={ConsortiumPage} />
              <ContentRoute exact path="/consortium/new" component={ConsortiumCreate} />
              <ContentRoute exact path="/consortium/:consortiumId" component={ConsortiumUpdate} />

              <ContentRoute exact path="/organizations" component={OrganizationPage} />
              <ContentRoute exact path="/organizations/new" component={OrganizationCreate} />
              <ContentRoute exact path="/organizations/:orgId" component={OrganizationUpdate} />

              <ContentRoute exact path="/fees" component={FeesPage} />
              <ContentRoute exact path="/fees/:feeId" component={FeesUpdate} />
              <ContentRoute exact path="/fees/view/:feeId" component={FeesView} />

              <ContentRoute exact path="/taxes" component={TaxPage} />
              <ContentRoute exact path="/taxes/:type" component={TaxCreate} />

              <ContentRoute exact path="/promotions/loyalty" component={LoyaltyPage} />
              <ContentRoute exact path="/promotions/loyalty/:promoCodeId" component={LoyaltyUpdate} />
              <ContentRoute exact path="/promotions/sales" component={SalesPage} />
              <ContentRoute exact path="/promotions/sales/:promoCodeId" component={SalesCreate} />

              {/* AGENT PROFILE */}
              <ContentRoute exact path="/agentprofile/:flag" component={Agentlist} />
              <ContentRoute exact path="/create" component={Createprofile} />
              <ContentRoute exact path="/agentprofile/update/:userId" component={Updateprofile} />

              {/* CREATE CONTRACT  */}
              <ContentRoute exact path="/Create-contract" component={CreateContract} />
              <ContentRoute exact path="/contractmanagement/Create-class/:tariffId" component={Createclass} />


              <ContentRoute exact path="/contractmanagement/:tariffId" component={Contractmanagement} />
              <ContentRoute exact path="/contractlist" component={Contractlist} />
              <ContentRoute exact path="/newcontractlist" component={NewContractlist} />
              <ContentRoute exact path="/bulkUpdate" component={BulkUpdate} />
              <ContentRoute exact path="/newClass" component={NewClass} />
              <ContentRoute exact path="/classes/:SK/:tariffId" component={Addclasses} />
              <ContentRoute exact path="/prices/:tariffId/:classId/:priceId" component={ViewAddPrices} />
              <ContentRoute exact path="/newTariff" component={NewTariff} />
              <ContentRoute exact path="/newPrix" component={NewPrix} />
              <ContentRoute exact path="/FraudCheck" component={FraudChecklistPage} />
              <ContentRoute exact path="/SalesPromotion" component={SalesPromotion} />
              <ContentRoute exact path="/SalesEdit" component={SalesEdit} />
              <ContentRoute exact path="/NewSales" component={SalesEdit} />
              <ContentRoute exact path="/FraudCheck" component={FraudChecklistPage} />
              <ContentRoute exact path="/Branding" component={BrandingPage} />
              <ContentRoute exact path="/createBranding/:id" component={CreateBranding} />
              <ContentRoute exact path="/createBranding" component={CreateBranding} />
              <ContentRoute exact path="/SalesEdit/:id" component={SalesEdit} />






              {/* MY FILES  */}
              <ContentRoute exact path="/myfiles" component={Myfile} />
              <ContentRoute exact path="/booking-confirmation" component={BookingConfirmationContainer} />
              <ContentRoute exact path="/sunquest-cache-admin" component={SunquestAdminList} />

              <Redirect to="/error" />
            </Switch>
          </Suspense>
        </Layout>
      )}
    </Switch>
  );
}


